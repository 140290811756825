<template>
  <div>
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      :loading="loading"
      flat
      class="d-flex justify-space-between"
    >
      <div
        v-for="(coluna, index) in colunasKanban"
        :key="index"
        :style="{
          width: `calc((100% - ${(colunasKanban.length - 1) * 27}px) / ${
            colunasKanban.length
          })`,
        }"
      >
        <v-card outlined flat elevation="3">
          <v-card-title class="text-subtitle-1 pa-2">
            <span class="font-weight-bold" style="color: rgb(35, 45, 88)">{{
              coluna.text
            }}</span>
            <v-sheet
              outlined
              class="mx-2 px-3"
              style="
                border: 1px solid #232d58;
                border-radius: 16px !important;
                border-color: rgb(35, 45, 88);
              "
            >
              {{ coluna.items.length }}</v-sheet
            >
          </v-card-title>
          <v-card
            v-if="coluna.items.length > 0"
            :style="`${'overflow: auto;'}`"
            class="pa-0"
            max-height="517px"
            flat
            solo
          >
            <v-list
              v-for="(ordemServico, index2) in coluna.items"
              :key="index2"
              class="px-2"
            >
              <v-card
                class="pa-1 d-flex flex-column"
                outlined
                flat
                elevation="3"
                hover
                @click.stop="goToOrdemServico(ordemServico)"
              >
                <!-- OS -->
                <span class="text-subtitle-2 d-flex">
                  <div style="color: #757575" class="mr-1">OS:</div>
                  <span class="font-weight-bold">{{ ordemServico.id }}</span>
                </span>
                <!-- Cliente -->
                <span class="text-subtitle-2 text-subtitle-2 d-flex">
                  <div style="color: #757575" class="mr-1">
                    {{ $tc("global.cliente", 2) }}:
                  </div>
                  {{ capitalizeWords(ordemServico.cliente_nome.toLowerCase()) }}
                </span>
                <!-- Data Criada -->
                <span
                  v-if="statusDataAbertura(ordemServico, coluna.value)"
                  class="text-subtitle-2 d-flex"
                >
                  <div style="color: #757575" class="mr-1">
                    {{ $tc("global.abertura") }}:
                  </div>
                  {{ ordemServico.data_criada | dateFormat("dd/MM/yyyy") }}
                </span>
                <!-- Data Agendamento -->
                <span
                  v-if="statusDataAgendamento(ordemServico, coluna.value)"
                  class="text-subtitle-2 d-flex"
                >
                  <div style="color: #757575" class="mr-1">
                    {{ $tc("global.agendamento") }}:
                  </div>
                  {{
                    ordemServico.data_pronta_instalacao
                      | dateFormat("dd/MM/yyyy")
                  }}
                </span>
                <!-- Data Instalação -->
                <span
                  v-if="statusDataInstalacao(ordemServico, coluna.value)"
                  class="text-subtitle-2 d-flex"
                >
                  <div style="color: #757575" class="mr-1">
                    {{ $tc("global.inicioServico") }}:
                  </div>
                  {{ ordemServico.data_instalacao | dateFormat("dd/MM/yyyy") }}
                </span>
                <!-- Data Conclusão -->
                <span
                  v-if="statusDataConclusao(ordemServico, coluna.value)"
                  class="text-subtitle-2 d-flex"
                >
                  <div style="color: #757575" class="mr-1">
                    {{ $tc("global.fimServico") }}:
                  </div>
                  {{ ordemServico.data_conclusao | dateFormat("dd/MM/yyyy") }}
                </span>

                <!-- CHIPS -->
                <div class="d-flex align-center">
                  <!-- piscina -->
                  <v-chip
                    v-if="ordemServico.tipo === 'VENDAS'"
                    x-small
                    class="mr-1 text-caption"
                    style="background-color: #3473b8; color: white"
                  >
                    {{ $tc("global.piscina") }}
                  </v-chip>
                  <!-- manual -->
                  <v-chip
                    v-else-if="ordemServico.tipo === 'MANUAL'"
                    x-small
                    class="mr-1 text-caption"
                    style="background-color: #232d58; color: white"
                  >
                    {{ $tc("global.manual") }}
                  </v-chip>

                  <!-- garantia -->
                  <v-chip
                    v-if="ordemServico.garantia"
                    x-small
                    class="mr-1 text-caption"
                    style="background-color: #757575; color: white"
                  >
                    {{ $tc("global.garantia") }}
                  </v-chip>
                  <!-- faturado -->
                  <v-chip
                    v-if="
                      ordemServico.status === 5 && ordemServico.data_faturado
                    "
                    x-small
                    class="mr-1 text-caption"
                    style="background-color: #1da328; color: white"
                  >
                    {{ $tc("global.faturado") }}</v-chip
                  >
                  <!-- enviado caixa -->
                  <v-chip
                    v-else-if="
                      ordemServico.status === 5 &&
                      ordemServico.data_enviado_caixa
                    "
                    x-small
                    class="mr-1"
                    style="background-color: #1da328; color: white"
                  >
                    {{ $tc("global.enviadoCaixa") }}</v-chip
                  >

                  <v-spacer></v-spacer>
                  <!-- botão editar kanban -->
                  <v-btn
                    v-if="coluna.value !== 5"
                    icon
                    small
                    style="color: rgb(35, 45, 88)"
                    @click.stop="
                      mudarStatusKanban(ordemServico.id, coluna.value)
                    "
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </div>
              </v-card>
            </v-list>
          </v-card>
        </v-card>
      </div>
    </v-card>
    <!-- Mobile -->
    <v-card v-else flat>
      <div v-for="(coluna, index) in colunasKanban" :key="index">
        <v-card outlined flat class="mb-2">
          <v-card-title class="text-subtitle-1 pa-2">
            <span class="font-weight-bold" style="color: rgb(35, 45, 88)">{{
              coluna.text
            }}</span>
            <v-sheet
              outlined
              class="mx-2 px-3"
              style="
                border: 1px solid #232d58;
                border-radius: 16px !important;
                border-color: rgb(35, 45, 88);
              "
            >
              {{ coluna.items.length }}</v-sheet
            >
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              style="color: rgb(35, 45, 88)"
              @click.stop="abriFecharColuna(index)"
            >
              <v-icon>{{
                coluna.open ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card
            v-if="coluna.items.length > 0"
            class="pa-0"
            flat
            solo
            v-show="coluna.open"
          >
            <v-list
              v-for="(ordemServico, index2) in coluna.items"
              :key="index2"
              class="px-2"
            >
              <v-card
                class="pa-1 d-flex flex-column"
                outlined
                flat
                hover
                @click.stop="goToOrderService(ordemServico)"
              >
                <span class="text-subtitle-2 d-flex">
                  <div style="color: #757575" class="mr-1">OS:</div>
                  <span class="font-weight-bold">{{ ordemServico.id }}</span>
                </span>

                <span class="text-subtitle-2 d-flex">
                  <div style="color: #757575" class="mr-1">
                    {{ $tc("global.cliente") }}:
                  </div>
                  {{ capitalizeWords(ordemServico.cliente_nome.toLowerCase()) }}
                </span>

                <span
                  v-if="statusDataAbertura(ordemServico, coluna.value)"
                  class="text-subtitle-2 d-flex"
                >
                  <div style="color: #757575" class="mr-1">
                    {{ $tc("global.abertura") }}:
                  </div>
                  {{ ordemServico.data_criada | dateFormat("dd/MM/yyyy") }}
                </span>

                <span
                  v-if="statusDataAgendamento(ordemServico, coluna.value)"
                  class="text-subtitle-2 d-flex"
                >
                  <div style="color: #757575" class="mr-1">
                    {{ $tc("global.agendamento") }}:
                  </div>
                  {{
                    ordemServico.data_pronta_instalacao
                      | dateFormat("dd/MM/yyyy")
                  }}
                </span>

                <span
                  v-if="statusDataInstalacao(ordemServico, coluna.value)"
                  class="text-subtitle-2 d-flex"
                >
                  <div style="color: #757575" class="mr-1">
                    {{ $tc("global.inicioServico") }}:
                  </div>
                  {{ ordemServico.data_instalacao | dateFormat("dd/MM/yyyy") }}
                </span>

                <span
                  v-if="statusDataConclusao(ordemServico, coluna.value)"
                  class="text-subtitle-2 d-flex"
                >
                  <div style="color: #757575" class="mr-1">
                    {{ $tc("global.fimServico") }}:
                  </div>
                  {{ ordemServico.data_conclusao | dateFormat("dd/MM/yyyy") }}
                </span>

                <div class="d-flex align-center">
                  <!-- piscina -->
                  <v-chip
                    v-if="ordemServico.tipo === 'VENDAS'"
                    x-small
                    class="mr-1 text-caption"
                    style="background-color: #3473b8; color: white"
                  >
                    {{ $tc("global.piscina") }}
                  </v-chip>
                  <!-- manual -->
                  <v-chip
                    v-else-if="ordemServico.tipo === 'MANUAL'"
                    x-small
                    class="mr-1 text-caption"
                    style="background-color: #232d58; color: white"
                  >
                    {{ $tc("global.manual") }}
                  </v-chip>

                  <!-- garantia -->
                  <v-chip
                    v-if="ordemServico.garantia"
                    x-small
                    class="mr-1 text-caption"
                    style="background-color: #757575; color: white"
                  >
                    {{ $tc("global.garantia") }}
                  </v-chip>
                  <!-- faturado -->
                  <v-chip
                    v-if="
                      ordemServico.status === 5 && ordemServico.data_faturado
                    "
                    x-small
                    class="mr-1 text-caption"
                    style="background-color: #1da328; color: white"
                  >
                    {{ $tc("global.faturado") }}</v-chip
                  >
                  <!-- enviado caixa -->
                  <v-chip
                    v-else-if="
                      ordemServico.status === 5 &&
                      ordemServico.data_enviado_caixa
                    "
                    x-small
                    class="mr-1 text-caption"
                    style="background-color: #1da328; color: white"
                  >
                    {{ $tc("global.enviadoCaixa") }}</v-chip
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="coluna.value !== 5"
                    icon
                    small
                    style="color: rgb(35, 45, 88)"
                    @click.stop="
                      mudarStatusKanban(ordemServico.id, coluna.value)
                    "
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </div>
              </v-card>
            </v-list>
          </v-card>
        </v-card>
      </div>
    </v-card>
    <DialogMudarStatusKanban
      v-if="dialogMudarStatusKanban"
      :dialogMudarStatusKanban.sync="dialogMudarStatusKanban"
      :ordemServico="ordemServico"
      :statusId="statusOrdemServico"
      @fetch-ordens-servicos="fetchOrdensServicos"
    />
  </div>
</template>

<script>
export default {
  name: "KanbanOrdemServico",

  components: {
    DialogMudarStatusKanban: () => import("./DialogMudarStatusKanban.vue"),
  },

  props: {
    ordem_servicos: {
      type: Array,
    },
    search: {
      type: String,
    },
  },

  data() {
    return {
      loading: true,
      dialogMudarStatusKanban: false,
      ordemServico: null,
      statusOrdemServico: null,
      colunasKanban: [
        {
          text: this.$tc("global.pendentes", 2),
          value: 1,
          items: [],
          open: false,
        },
        {
          text: this.$tc("global.emPreparação"),
          value: 2,
          items: [],
          open: false,
        },
        {
          text:
            this.$tc("global.aguardando") + " " + this.$tc("global.instalacao"),
          value: 3,
          items: [],
          open: false,
        },
        {
          text: "Em " + this.$tc("global.instalacao"),
          value: 4,
          items: [],
          open: false,
        },
        {
          text: this.$tc("global.finalizado", 2),
          value: 5,
          items: [],
          open: false,
        },
      ],
    };
  },

  methods: {
    abriFecharColuna(index) {
      this.colunasKanban.find((coluna) => {
        if (coluna.open && coluna.value !== this.colunasKanban[index].value) {
          coluna.open = false;
        }
      });
      this.colunasKanban[index].open = !this.colunasKanban[index].open;
    },
    mudarStatusKanban(ordemServicoId, status) {
      this.ordemServico = ordemServicoId;
      this.statusOrdemServico = status;
      this.dialogMudarStatusKanban = true;
    },

    goToOrdemServico(ordemServicoId) {
      this.$emit("select-ordem-servico", ordemServicoId);
    },

    fetchOrdensServicos() {
      this.$emit("fetch-ordens-servicos");
    },

    capitalizeWords(str) {
      let words = str.split(" ");

      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }

      return words.join(" ");
    },

    statusDataAbertura(ordemServico, status) {
      if ([1, 2].includes(status) && ordemServico.data_criada) {
        return true;
      } else {
        return false;
      }
    },

    statusDataAgendamento(ordemServico, status) {
      if ([3].includes(status) && ordemServico.data_pronta_instalacao) {
        return true;
      } else {
        return false;
      }
    },

    statusDataInstalacao(ordemServico, status) {
      if ([4].includes(status) && ordemServico.data_instalacao) {
        return true;
      } else {
        return false;
      }
    },

    statusDataConclusao(ordemServico, status) {
      if ([5].includes(status) && ordemServico.data_conclusao) {
        return true;
      } else {
        return false;
      }
    },
  },

  computed: {},

  watch: {
    search() {
      if (this.search !== null && this.search !== "") {
        const searchTerm = this.search.toLowerCase();
        this.colunasKanban.forEach((element) => {
          element.items = this.ordem_servicos.filter(
            (ordemServico) =>
              ordemServico.status === element.value &&
              ordemServico.cliente_nome.toLowerCase().includes(searchTerm)
          );
        });
      } else {
        this.colunasKanban.forEach((element) => {
          element.items = this.ordem_servicos.filter(
            (ordemServico) => ordemServico.status === element.value
          );
        });
      }
    },
  },

  async mounted() {
    this.loading = true;
    this.colunasKanban.forEach((element) => {
      element.items = this.ordem_servicos.filter(
        (ordemServico) => ordemServico.status === element.value
      );
    });
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
